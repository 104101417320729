import {useState, useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import {webProxyApi} from "api/WebProxyApi";
import {NvrInfoApi} from "api/NvrInfoApi";
import {Modal} from "antd";


const MydwContainer = (props) => {

    const [searchParams] = useSearchParams();

    const dwToken = searchParams.get('token');

    let host;
    let user;
    let firmwareVersion;
    let viewerVersion;
    let brand;
    let type;
    let token;
    let webIp
    let webPort
    let sessionIp;
    let sessionPort;
    let mydwUrl;
    const connect = async () => {
        try {
            await NvrInfoApi.getDwInfoApi(dwToken)
                .then(data => {
                    host = data.data.host;
                    user = data.data.user;
                    brand = data.data.brand;
                    type = data.data.type;
                    token = data.data.token;
                    viewerVersion = data.data.viewerVersion;
                    firmwareVersion = data.data.firmwareVersion;
                    mydwUrl = data.data.mydwUrl;
                    console.log(host)
                    console.log(firmwareVersion)
                })


            try {
                let webResult = null;
                let generationUrl;
                ({data: webResult} = await webProxyApi.webServerInfoApi(host.trim().toUpperCase()));

                webIp = webResult.address;
                webPort = webResult.port;
                if(mydwUrl === undefined){
                    mydwUrl = "stella.mydw.cloud";
                }

                if (brand.toUpperCase() === "FOCUS") {
                    console.log(brand);
                    //if (type.toUpperCase() === "NVR") {
                    try {
                        console.log(type);
                        await NvrInfoApi.getTimeMydwFocusApi(`${webIp}:${webPort}`, user)
                            .then(data => {
                                    console.log("NvrInfoApi.getTimeMydwFocusApi")
                                    let time = data.data.time;
                                    let uid = data.data.uid;
                                    let splitString = viewerVersion.split(".")

                                    // if (type.toUpperCase() === "NVR") {

                                        if(Number(splitString[3]) == 4 ) {
                                            generationUrl = `http://${webIp}:${webPort}/cgi-bin/login_ex?login_type=1&time=${time}&uid=${uid}&key=${token}&smartWebViewer=https://pf-wasm.s3.us-west-1.amazonaws.com/UMiniWebViewer_1.2.5.4&logoutPage=https://${window.location.hostname}`;
                                        } else if (Number(splitString[3]) == 8){
                                            generationUrl = `http://${webIp}:${webPort}/cgi-bin/login_ex?login_type=1&time=${time}&uid=${uid}&key=${token}&smartWebViewer=https://pf-wasm.s3.us-west-1.amazonaws.com/UMiniWebViewer_1.2.5.8&logoutPage=https://${window.location.hostname}`;

                                        }else if (Number(splitString[3]) == 15){
                                            generationUrl = `http://${webIp}:${webPort}/cgi-bin/login_ex?login_type=1&time=${time}&uid=${uid}&key=${token}&smartWebViewer=https://pf-wasm.s3.us-west-1.amazonaws.com/UMiniWebViewer_1.2.5.15&logoutPage=https://${window.location.hostname}`;
                                        } else if (Number(splitString[3]) == 14){
                                            generationUrl = `http://${webIp}:${webPort}/cgi-bin/login_ex?login_type=1&time=${time}&uid=${uid}&key=${token}&smartWebViewer=https://pf-wasm.s3.us-west-1.amazonaws.com/UMiniWebViewer_1.2.5.14&logoutPage=https://${window.location.hostname}`;
                                        }else if (Number(splitString[3]) == 12){
                                            generationUrl = `http://${webIp}:${webPort}/cgi-bin/login_ex?login_type=1&time=${time}&uid=${uid}&key=${token}&smartWebViewer=https://pf-wasm.s3.us-west-1.amazonaws.com/UMiniWebViewer_1.2.5.12&logoutPage=https://${window.location.hostname}`;
                                        }else {
                                            generationUrl = `http://${webIp}:${webPort}/cgi-bin/login_ex?login_type=1&time=${time}&uid=${uid}&key=${token}&smartWebViewer=https://pf-wasm.s3.us-west-1.amazonaws.com/UMiniWebViewer_1.2.5.1&logoutPage=https://${window.location.hostname}`;
                                        }
                                    // } else {
                                    //     generationUrl = `http://${webIp}:${webPort}/cgi-bin/login_ex?login_type=1&grant_type=token&time=${time}&uid=${uid}&key=${token}&smartWebViewer=https://pf-wasm.s3.us-west-1.amazonaws.com/UMiniWebViewer_1.2.5.1&logoutPage=https://${mydwUrl}`;
                                    //
                                    // }
                                    console.log(generationUrl)
                                    window.location.href = generationUrl;
                                }
                            );
                    } catch (error) {
                        console.log(error)
                    }
                    //} else if (type.toUpperCase() === "DVR") {

                    //}

                } else if (brand.toUpperCase() === "TVT") {
                    console.log(firmwareVersion);
                    ({data: webResult} = await webProxyApi.webServerTvtSessionApi(host.trim().toUpperCase()));
                    sessionIp = webResult.reply.address;
                    sessionPort = webResult.reply.port;

                    try {

                        await NvrInfoApi.getTimeMydwTvtApi(`${sessionIp}:${sessionPort}`, user)
                            .then(data => {
                                    let time = data.data.time;
                                    let uid = data.data.uid;
                                    let subVersion = firmwareVersion.split('.');
                                    if (type.toUpperCase() === "NVR") {
                                        // generationUrl = `http://${webIp}:${webPort}/cgi-bin/login_ex?login_type=1&grant_type=token&time=${time}&uid=${uid}&key=${token}&resource_base_url=https%3A%2F%2Fmydw-nvr-scripts.s3.us-west-1.amazonaws.com%2Fnvr%2F${subVersion[0]}.${subVersion[1]}.${subVersion[2]}%2F`;
                                        generationUrl = `http://${webIp}:${webPort}/cgi-bin/login_ex?login_type=1&grant_type=token&time=${time}&uid=${uid}&key=${token}&resource_base_url=https%3A%2F%2Fmydw-nvr-scripts.s3.us-west-1.amazonaws.com&fwversion=${firmwareVersion}`
                                    } else {
                                        generationUrl = `http://${webIp}:${webPort}/cgi-bin/login_ex?login_type=1&grant_type=token&time=${time}&uid=${uid}&key=${token}&resource_base_url=https%3A%2F%2Fmydw-nvr-scripts.s3.us-west-1.amazonaws.com%2Fnvr%2F1.4.8%2F`;
                                    }
                                    window.location.href = generationUrl;
                                }
                            );
                    } catch (error) {
                        console.log(error)
                    }

                } else {

                }

            } catch (error) {
                Modal.error({
                    content: 'Recorder is offline'
                })
                console.log(error)
            }
        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        connect();
    }, []);

}

export default MydwContainer;
